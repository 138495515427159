<template>
  <b-overlay :show="loading">
    <section>
      <b-row>
        <b-col md="12">
          <b-card>
            <b-form>
              <b-row>
                <b-col sm="12" md="8">
                  <b-form-group label="Judul" label-for="Judul">
                    <b-form-input id="Judul" v-model="form.name" />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="4">
                  <b-form-group>
                    <label for="Pilih Paket">Pilih Kategori Paket</label>
                    <small class="text-danger"> <b> (*)</b></small>
                    <v-select
                      v-model="form.category_paket_id"
                      label="text"
                      :options="optpaket_id"
                      :reduce="(option) => option.value"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" md="2">
                  <b-form-group label="Waktu (Detik)" label-for="timer_second">
                    <b-form-input
                      id="timer_second"
                      v-model="form.timer_second"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="3" v-if="type == 'pauli'">
                  <b-form-group label="Tambah Opsi Soal" label-for="opsi">
                    <b-form-input v-model="formopsi" />
                    <!-- <b-form-input v-model="formopsi" /> -->
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                  <b-form-group label="--" label-for="opsi">
                    <b-button variant="primary" @click="addOpsi()"
                      >Tambah Opsi</b-button
                    >
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="3">
                  <b-form-group>
                    <label for="total-bobot" class="text-primary">
                      <strong><i>Total Bobot Kolom</i></strong>
                    </label>
                    <b-form-input :value="totalBobotSoal" disabled />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <div v-if="type == 'pauli'">
              <b-row v-for="(data, index) in form.sub_soals" :key="data.id">
                <b-col sm="12" md="4" lg="3">
                  <b-form-group>
                    <label :for="'h-baris-' + (index + 1)">
                      <strong
                        ><i>{{ "Soal Baris " + (index + 1) }}</i></strong
                      >
                    </label>
                    <b-form-input
                      :id="'h-baris-' + data.id"
                      placeholder="Ex: 1 + 2"
                      v-model="data.content"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="4" lg="3">
                  <b-form-group
                    :label="'Jawaban (Ganjil: 1, Genap: 0)'"
                    :label-for="'h-baris-' + (index + 1)"
                  >
                    <b-form-input
                      :id="'h-baris-' + data.id"
                      placeholder="Isi 0 atau 1"
                      v-model="data.secret_key"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="4" lg="3">
                  <b-form-group
                    :label="'Bobot Soal'"
                    :label-for="'bobot-baris-' + (index + 1)"
                  >
                    <b-form-input
                      :id="'bobot-baris-' + data.id"
                      placeholder="Ex: 10"
                      v-model="data.bobot"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="4" lg="3">
                  <b-form-group
                    :label="'Aksi Baris ' + (index + 1)"
                    :label-for="'h-baris-' + (index + 1)"
                  >
                    <b-button
                      v-if="data.id == null"
                      class="mr-1"
                      variant="success"
                      @click="TambahSub(data)"
                      ><feather-icon icon="SaveIcon"
                    /></b-button>
                    <b-button
                      v-if="data.id != null"
                      class="mr-1"
                      variant="info"
                      @click="EditSub(data)"
                      ><feather-icon icon="SaveIcon"
                    /></b-button>
                    <b-button
                      v-if="data.id != null"
                      variant="primary"
                      @click="deleteOpsi(data)"
                      ><feather-icon icon="TrashIcon"
                    /></b-button>
                    <b-button
                      v-if="data.id == null"
                      variant="danger"
                      @click="deleteOpsi(data, true)"
                      ><feather-icon icon="MinusCircleIcon"
                    /></b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-button
              variant="success"
              @click.prevent="submit"
              class="w-full mt-3"
            >
              Simpan
            </b-button>
            <!-- <b-button
              variant="outline-primary"
              @click.prevent="clearForm"
              class="w-full mt-3"
            >
              Reset
            </b-button> -->
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BOverlay,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
const limitSoal = 5; // limit pembuatan soal
const mb = 1024;

export default {
  components: {
    vSelect,
    quillEditor,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BOverlay,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      mapels: [],
      // default data
      form: {
        mapel_id: null,
        name: null,
        pembahasan: null,
        timer_second: 30,
        sub_soals: [],
      },
      opsi: null,
      active_soal_attachment: false,
      judul: null,
      selectedOption: 0,
      file: null,
      sum_option: null,
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      optmapel_id: [],
      optpaket_id: [],
      timer_second: 0,
      name: null,
      type: "pauli",
      mapels: [
        {
          value: "Matematika Kelas X IPA 1",
        },
      ],
      is_correct: null,
      typesSoal: [
        {
          label: "Pilihan Ganda",
          value: "pg",
        },
        {
          label: "Multiple Choice",
          value: "multiple_choice",
        },
        {
          label: "Pauli",
          value: "pauli",
        },
        {
          label: "Pernyataan Benar Salah",
          value: "benar_salah",
        },
      ],
      bsSoal: [
        { text: "Benar", value: "benar" },
        { text: "Salah", value: "salah" },
      ],
      opsisSoal: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
      sum_options: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
      ],
      masterOptions: [
        {
          id: 1,
          label: "A",
          value: "A",
        },
        {
          id: 2,
          label: "B",
          value: "B",
        },
        {
          id: 3,
          label: "C",
          value: "C",
        },
        {
          id: 4,
          label: "D",
          value: "D",
        },
        {
          id: 5,
          label: "E",
          value: "E",
        },
      ],
      formopsi: null,
      availableOptions: [
        {
          content: "",
        },
      ],
      sub_soals: [],
      activeAction: null,
    };
  },
  watch: {},
  computed: {
    totalBobotSoal() {
      return this.form.sub_soals
        .reduce((total, soal) => {
          total += parseFloat(soal.bobot);
          return total;
        }, 0)
        .toFixed(2);
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    deleteOpsi(data, justArray = false) {
      if (justArray) {
        let subSoals = Object.assign([], this.form.sub_soals);
        const findIndex = subSoals.findIndex((soal) => soal?.key == data.key);
        if (findIndex > -1) {
          subSoals.splice(findIndex, 1);
          this.form.sub_soals = subSoals;
        }
      } else {
        this.$swal({
          title: "Anda Yakin?",
          text: "Data tidak bisa dikembalikan!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Hapus!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let payload = {
              id: data.id,
              soal_id: data.soal_id,
              content: data.content,
              secret_key: data.secret_key,
              fungsi: 1,
            };
            this.$store
              .dispatch("banksoal/CRUSubPauli", [payload])
              .then(() => {
                this.displaySuccess({
                  text: "Data berhasil dihapus",
                });
                this.getPauli();
              })
              .catch((e) => {
                this.displayError(e);
                return false;
              });
          } else if (result.dismiss === "cancel") {
            this.$swal({
              title: "Batal",
              text: "Data aman, kamu batal menghapus data :)",
              icon: "error",
              // timer: 1000,
              allowOutsideClick: true,
              customClass: {
                confirmButton: "btn btn-success",
              },
              buttonsStyling: false,
            });
          }
        });
      }
    },
    addOpsi() {
      const subSoals = Object.assign([], this.form.sub_soals);
      for (let i = 0; i < this.formopsi; i++) {
        const index = subSoals.length;
        subSoals.push({
          key: index,
          soal_id: this.form.id,
          content: null,
          secret_key: null,
          bobot: 1,
        });
      }

      this.form.sub_soals = subSoals;
    },
    clearForm() {
      this.form = [
        {
          judul: null,
          name: null,
          file: null,
          option: null,
          sub_soals: [],
          secretKey: null,
          timer_second: 1,
        },
      ];
      this.type = "pauli";
      this.mapel_id = null;

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    addAttachSoal(ind, e) {
      document.getElementById(`file_soal_${ind}`).click();
    },
    addAttachSoalOption(ind, e) {
      document.getElementById(ind).click();
    },
    addSoal() {
      if (this.form.length >= limitSoal) {
        this.$vs.notify({
          title: "Batas Pembuatan Soal Tercapai",
          text: "",
          variant: "warning",
          position: "top-right",
        });
        return false;
      }

      let data = {
        no: this.form.length + 1,
        judul: null,
        name: null,
        file: null,
        option: null,
        sub_soals: [],
        // is_correct: null,
        timer_second: 1,
      };

      if (this.selectedOption != null) {
        for (let i = 0; i < this.selectedOption; i++) {
          let option = this.getOption(i);
          data.sub_soals.push({
            key: i,
            option,
            secret_key: null,
            content: null,
            bobot: 1,
          });
        }
      }

      //
      this.form.push(data);
    },
    updateForm(val) {
      let vm = this;
      this.$route.meta.breadcrumb[2].url =
        "/soal/daftarsoal?mp=" + this.$route.query.mp;
      val.forEach((item, index) => {
        // Change when user choose Jumlah Opsi
        if (form.selectedOption != null) {
          let numOpsi = parseInt(form.selectedOption.name);
          if (form.sub_soals.length > 0) {
            form.sub_soals = [];
          }

          if (form.sub_soals.length < 1) {
            for (let i = 0; i < numOpsi; i++) {
              let option = this.getOption(i);
              vm.form[index].sub_soals[i] = {
                key: i,
                option,
                file: null,
                content: null,
              };
            }
          }
        }
      });
    },
    getEditorOptions(id_file_input) {
      // get event link click in dynamic options
      return {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
            handlers: {
              link: function (val) {
                document.getElementById(id_file_input).click();
              },
            },
          },
        },
      };
    },
    getOption(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },

    getKunjaw(num) {
      switch (num) {
        case 0:
          num = "A";
          break;
        case 1:
          num = "B";
          break;
        case 2:
          num = "C";
          break;
        case 3:
          num = "D";
          break;
        case 4:
          num = "E";
          break;
      }

      return num;
    },
    removeOptions(ind) {
      if (this.sub_soals.length > 1) {
        this.sub_soals.splice(ind, 1);
      }
    },
    optionNull() {
      let hasNullOption = this.form.sub_soals.find(
        (soal) =>
          soal.content == null ||
          soal.content == "" ||
          soal.secret_key == null ||
          !soal.bobot
      );

      return hasNullOption ? true : false;
    },
    addOption() {
      // untuk menambah opsi
      this.sub_soals.push({
        option: null,
        content: null,
      });
    },
    fileToBase64(fileObj) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(fileObj);
      });
    },
    async TambahSub(data) {
      let payload = {
        soal_id: this.$route.params.id,
        content: data.content,
        secret_key: data.secret_key,
      };
      try {
        (this.loading = true),
          await this.$store.dispatch("banksoal/CRUSubPauli", [payload]);
        (this.loading = false), (this.activeAction = "tambah"), this.getPauli();
        this.displaySuccess({
          text: "Soal berhasil di" + this.activeAction,
        });
      } catch (e) {
        (this.loading = false), this.displayError(e);
        return false;
      }
    },
    async EditSub(data) {
      let payload = {
        id: data.id,
        soal_id: data.soal_id,
        content: data.content,
        secret_key: data.secret_key,
      };
      try {
        (this.loading = true),
          await this.$store.dispatch("banksoal/CRUSubPauli", [payload]);
        (this.loading = false),
          (this.activeAction = "Edit"),
          this.displaySuccess({
            text: "Soal berhasil di" + this.activeAction,
          });
      } catch (e) {
        (this.loading = false), this.displayError(e);
        return false;
      }
    },
    validateForm() {
      // Check if there question with empty soal || empty secret
      let thereEmptyQuest = 0;

      if (this.type == "pauli") {
        this.form.name == "" ||
          this.form.name == null ||
          this.form.paket_id == "" ||
          this.form.paket_id == null ||
          this.form.timer_second == "" ||
          this.form.timer_second == null;
      }

      if (this.type == null || thereEmptyQuest > 0) {
        return false;
      }

      return true;
    },
    async submit() {
      // lakukan validasi jika ada form soal yg belum diisi
      if (!this.validateForm()) {
        this.displayWarning({
          title: "Perhatian",
          text: "Tidak dapat merubah soal, harap lengkapi data-data yang wajib diisi",
        });
        return false;
      }

      // create var for form data
      const fd = new FormData();
      if (this.type == "pauli") {
        if (this.optionNull()) {
          this.displayWarning({
            title: "Perhatian",
            text: "Tidak dapat menambahkan soal, harap isi opsi yang masih kosong",
          });
          return false;
        }
      }
      let payload = {
        id: this.$route.params.id,
        user_id: this.user.id,
        mapel_id: this.form.mapel_id,
        category_paket_id: this.form.category_paket_id,
        name: this.form.name,
        pembahasan: this.form.pembahasan,
        timer_second: this.form.timer_second,
      };

      const subSoals = this.form.sub_soals.map((soal) => {
        soal.bobot = parseFloat(soal.bobot);
        return soal;
      });
      payload.soals = subSoals;

      try {
        (this.loading = true),
          await this.$store.dispatch("banksoal/CRUPauli", [payload]);
        (this.loading = false),
          (this.activeAction = "tambah"),
          this.displaySuccess({
            text: "Soal berhasil di" + this.activeAction,
          });

        let url = `/master-admin/bank-soal?cat=${payload.category_paket_id}`;

        this.$router.push(url);
      } catch (e) {
        (this.loading = false), this.displayError(e);
        return false;
      }
    },
    checkQuery() {
      if (this.$route.query.s == undefined) {
        this.$router.push({ path: "/soal" });
      }
    },
    handleOptionFile(e, id) {
      // add file dynamic option to data
      let objFile = e.target.files[0];
      this.form[id.soal].soals[id.opsi].file = objFile;

      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.form[id.soal].soals[id.opsi].file = URL.createObjectURL(objFile);
      } else {
        this.form[id.soal].soals[id.opsi].file = null;
      }
    },
    handleFile(ind, e) {
      // add soal file to data
      let objFile = e.target.files[0];
      this.form[ind].file = objFile;
      if (
        ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
          objFile.type
        )
      ) {
        this.form[ind].preview_file = URL.createObjectURL(objFile);
      } else {
        this.form[ind].preview_file = null;
      }
    },
    unhandleFile(ind, e) {
      // remove soal file
      this.form[ind].file = null;
      this.form[ind].preview_file = null;
      e.target.value = null;
    },
    unhandleFileOption(e, id) {
      // remove file dynamic option
      this.form[id.soal].soals[id.opsi].file = null;
      this.form[id.soal].soals[id.opsi].preview_file = null;
      e.target.value = null;
    },
    resetHandleFile(ind, e) {
      this.$refs["file_" + ind].value = "";
    },
    resetHandleOptionFile(e, id_file_input) {
      this.$refs[id_file_input].value = "";
    },
    async getMyMapel() {
      const params = {
        guru_id: this.profile.id,
      };
      const mapels = await this.$store.dispatch("mapel/getMyMapel", params);
      mapels.map((item) => {
        if (form.mapel) {
          this.mapels.push(form.mapel);
        }
      });
    },
    doFormatRupiah() {
      this.banksoalDataId.price = this.formatRupiah(this.banksoalDataId.price);
      this.banksoalDataId.max_quota = this.formatRupiah(
        this.banksoalDataId.max_quota
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = form.id;
      this.banksoalDataId = item;
      this.activeAction = "ubah";
      this.doFormatRupiah();
      this.titleModal = "Ubah Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("banksoal/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getFormPauli() {
      this.loading = true;
      this.$store
        .dispatch("banksoal/indexId", this.$route.params.id)
        .then((res) => {
          this.loading = false;
          this.form = res.data;
          this.form.mapel_id = this.form.mapel.id;
          if (this.form.category_paket_id == 0) {
            this.form.category_paket_id = 0;
          }
          if (this.form.category_paket_id > 0) {
            this.form.category_paket_id = this.form.kategori_paket.id;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getPauli() {
      this.loading = true;
      let payload = {
        soal_id: this.form.id,
      };
      this.$store
        .dispatch("banksoal/indexPauli", payload)
        .then((res) => {
          this.loading = false;
          this.form.sub_soals = res.data.data;
          this.soals = res.data.data;
          this.selectedOption = res.data.total;
        })
        .catch((error) => {
          this.displayError(err);
          return false;
        });
    },
    getDataPaket() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let paket_id = response.data.data;
          paket_id.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optpaket_id = paket_id;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  async mounted() {
    this.getFormPauli();
    this.getDataPaket();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
